<template>
    <div id="orderDetails">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="$router.push({ name: 'historyOrder' })"
                >返回</el-button
            >
            <div class="title">查看订单详情</div>
        </div>
        <div>
            <el-card class="box-card infoBox" shadow="hover">
                <div class="clearfix" slot="header">
                    <div class="header_tit">客户信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>

                <div class="infoItem">
                    <span class="tit">客户姓名：</span><span>{{ checkForm.customerName }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">身份证号码：</span><span>{{ checkForm.idCard }}</span>
                </div>
                <div class="infoItem zjzInfo">
                    <span class="tit">证件照：</span>
                    <div class="zjz">
                        <div class="img">
                            <img
                                :src="checkForm.idCardFrontImagePath"
                                alt=""
                                @click="
                                    showbigImg = true;
                                    bigImgSrc = checkForm.idCardFrontImagePath;
                                "
                            />
                        </div>
                        <div class="img">
                            <img
                                :src="checkForm.idCardBackImagePath"
                                alt=""
                                @click="
                                    showbigImg = true;
                                    bigImgSrc = checkForm.idCardBackImagePath;
                                "
                            />
                        </div>
                    </div>
                </div>
            </el-card>
            <el-card class="box-card infoBox" shadow="hover">
                <div class="clearfix" slot="header">
                    <div class="header_tit">订单信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                
                <div class="infoItem" >
                    <span class="tit">订单号：</span><span>{{ checkForm.id }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约银行：</span><span>{{ checkForm.signingBank }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">银行卡号：</span><span>{{ checkForm.bankCardNumber }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">银行预留手机号：</span><span>{{ checkForm.mobileNumber }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约金额：</span><span>{{ checkForm.signingAmount }} 元</span>
                </div>
                <div class="infoItem">
                    <span class="tit">是否分期：</span><span>{{ checkForm.signingType == 0 ? "否" : "是" }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">分期数：</span><span>{{ checkForm.stagesNumber }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">签约状态：</span><span>{{ checkForm.signingStatusText }}</span>
                </div>
                <div class="infoItem">
                    <span class="tit">订单状态：</span><span>{{ checkForm.orderStatusText }}</span>
                </div>
            </el-card>
        </div>
        <checkImg :showbigImg="showbigImg" :bigImgSrc="bigImgSrc" @update:showbigImg="handleBigImageUpdate"></checkImg>
    </div>
</template>
<script>
import checkImg from "../../components/comm/checkImg.vue";
export default {
    name: "orderDetails",
    components: { checkImg },
   
    data() {
        return {
            checkId: null,
            checkForm: {},
            showbigImg: false,
            bigImgSrc: "",

         
        };
    },
    mounted() {
        this.checkId = this.$route.query.id;
        this.$ajax.get("//safepayment.com.cn:18080/contractOrders/get/" + this.checkId).then(res => {
            if ((res.status = 200 && res.data != null)) {
                this.checkForm = res.data;
                this.checkForm.idCardFrontImagePath =
                    "//safepayment.com.cn:18080/attachment" + res.data.idCardFrontImagePath;
                this.checkForm.idCardBackImagePath =
                    "//safepayment.com.cn:18080/attachment" + res.data.idCardBackImagePath;
            }
        });
    },
    methods: {
        handStatus(status) {
            switch (status) {
                case 0:
                    return "未签约";
                case 1:
                    return "已签约";
                case 2:
                    return "签约中";
                case 3:
                    return "已解约";
                case 5:
                    return "自动解约";
            }
        },
        handleBigImageUpdate(newValue) {
            this.showbigImg = newValue;
        }
    }
};
</script>
<style>
</style>
<style lang="scss" scoped>
#orderDetails {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    overflow: auto;
    padding-left: 17%;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding-right: 100px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        width: 80%;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }

    .infoBox {
        width: 80%;
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 12px 4px 12px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }

    .box-card {
        margin-bottom: 20px;
        width: 80%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        width: 80%;
    }
}
</style>

